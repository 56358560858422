// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBu2yiDvmYpmNm6JMropuWeXraqmh73QlY",
  authDomain: "ecollerceleadgenerationclone.firebaseapp.com",
  projectId: "ecollerceleadgenerationclone",
  storageBucket: "ecollerceleadgenerationclone.appspot.com",
  messagingSenderId: "555003614127",
  appId: "1:555003614127:web:6c1d9cc2165a4ddf49955a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;