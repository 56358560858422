import React from "react";

const PageHero = () => {
  return (
    <>
      <div className="mt-5" style={{ paddingTop: '50px' }}>
        <img
          className="inner_banner-background-image"
          src="/image/common/inner-bg.png"
          alt="image alt"
        
        />
        <div className="container">
          <div className="inner_banner-content-block">


          </div>
        </div>
      </div>

    </>
  );
};

export default PageHero;
