import React from "react";
import "./Dashboard.css";
import DashboardMenu from "./DashboardMenu";


const Dashboard = () => {



  return (
    <div>
      <div className="mt-5 mb-5">
        <DashboardMenu></DashboardMenu> 
      </div>
    </div>
  );
};

export default Dashboard;
