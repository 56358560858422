import React from 'react';

const Loading = () => {
  return (
    <>
      <div class="preloader-wrapper">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Loading;
