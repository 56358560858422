import React, { useEffect, useState } from "react";
import "./UserDashboard.css";
import LeadsForUserDashboard from "../components/Shared/LeadsForUserDashboard";
import { Link } from "react-router-dom";
import DashboardSidebar from "../components/Shared/DashboardSidebar";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../firebase.init";

const EmailTemplates = () => {
  

  return (
    <div className="container">
      <h2>Feel free to contact us: support@spand.top</h2>
    </div>
  );
};

export default EmailTemplates;
