import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import auth from "../../firebase.init";
import { useAuthState } from "react-firebase-hooks/auth";
import PageHero from "./PageHero";

const NavBar = () => {
  const [logo, setLogo] = useState({});
  const [user] = useAuthState(auth);
  const [admin, setAdmin] = useState([]);
  const location = useLocation();
  const [profile, setProfile] = useState([]);

  const handleSignout = () => {
    signOut(auth);
  };

  useEffect(() => {
    fetch(`https://leadgenerationclonetools-92a40ed0a4e4.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info[0]));
  }, []);
  useEffect(() => {
    fetch(`https://leadgenerationclonetools-92a40ed0a4e4.herokuapp.com/users`)
      .then((res) => res.json())
      .then((info) => setAdmin(info));
  }, []);
  useEffect(() => {
    fetch(`https://leadgenerationclonetools-92a40ed0a4e4.herokuapp.com/profiles/`)
      .then((res) => res.json())
      .then((info) => setProfile(info));
  }, []);

  const isAdmin = user && admin.some((adm) => adm.userEmail === user.email);

  const isHomePage = location.pathname === "/";
  const shouldRenderPageHero = !isHomePage;

  return (
    <>


      <header className="site-header site-header--transparent  bg--primary">
        <div className="container">
          {
            user ?
              <nav className="navbar site-navbar login-menu">

                <div className="brand-logo">
                  <Link to="/">

                    <img className="logo-light" src={logo.logo} alt="brand logo" />
                    {/* Dark version logo (logo must be White)*/}
                    <img
                      className="logo-dark"
                      src={logo.logo}
                      alt="brand logo"
                    />
                  </Link>
                </div>
                <div className="menu-block-wrapper">
                  <div className="menu-overlay" />
                  <nav className="menu-block" id="append-menu-header">
                    <div className="mobile-menu-head">
                      <Link to="/">
                        <img src={logo.logo} alt="brand logo" />
                      </Link>
                      <div className="current-menu-title" />
                      <div className="mobile-menu-close">×</div>
                    </div>
                    <ul className="site-menu-main">

                      <li className="nav-item">
                        <Link to="/find-leads" className="nav-link-item drop-trigger menu-link">
                          <div><i class="fas fa-search icon-margin" ></i></div> <div>Find Leads</div>
                        </Link>
                        <Link to="/my-leads" className="nav-link-item drop-trigger menu-link">
                          <i class="fas fa-user-friends icon-margin"></i> <div>My Leads</div>
                        </Link>
                        <Link to="/create-list" className="nav-link-item drop-trigger menu-link">
                          <i class="fas fa-folder-plus icon-margin"></i> <div>Create List</div>
                        </Link>
                        {/* <Link to="/email-templates" className="nav-link-item drop-trigger menu-link">
                          <i class="fas fa-envelope-open-text icon-margin"></i> <div>Email Templates</div>
                        </Link> */}
                        <Link to="/pricing" className="nav-link-item drop-trigger menu-link">
                          <i class="far fa-credit-card icon-margin"></i> Buy Credits
                        </Link>
                        <Link to="/contact" className="nav-link-item drop-trigger">
                          <i class="far fa-credit-card icon-margin"></i> Support
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>

               

                {user ? (
                  <>
                    {profile.map(pro => pro.userEmail === user?.email && (
                      <div className="nav-item dropdown mt-3 me-3">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={pro.profileImg || "https://st3.depositphotos.com/15648834/17930/v/450/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"}
                            alt="Profile"
                            className="rounded-circle me-2"
                            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                          />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end p-3">
                          <li>
                            <Link className="dropdown-item" to="/user-dashboard">
                              <i class="fa-solid fa-bars text-primary"></i>{" "}
                              Dashboard
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/pending-payment/">
                              <i class="fas fa-money-check-alt text-primary"></i>{" "}
                              Pending Payment
                            </Link>
                          </li>


                          <li>
                            <Link className="dropdown-item" to='/find-leads'> <i class="fas fa-window-maximize text-primary"></i>{" "}Find Leads</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={`/update-profile/${pro._id}`}> <i class="fa-solid fa-user text-primary"></i>{" "} Edit Profile</Link>
                          </li>


                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li>
                            <button className="dropdown-item" onClick={handleSignout}>
                              <i class="fa-solid fa-right-from-bracket text-primary"></i>
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    ))}


                    {isAdmin && (
                      <Link
                        to="/admin/dashboard"
                        className="btn-masco btn-masco--header rounded-pill btn-fill--up"
                      >
                        <span>Admin</span>
                      </Link>
                    )}
                  </>
                ) : (
                  <Link
                    to="/login"
                    className="btn-masco btn-masco--header rounded-pill btn-fill--up"
                  >
                    <span>Login</span>
                  </Link>
                )}


              </nav>
              :
              <nav className="navbar site-navbar">

                <div className="brand-logo">
                  <Link to="/">

                    <img className="logo-light" src={logo.logo} alt="brand logo" />
                    {/* Dark version logo (logo must be White)*/}
                    <img
                      className="logo-dark"
                      src={logo.logo}
                      alt="brand logo"
                    />
                  </Link>
                </div>
                <div className="menu-block-wrapper">
                  <div className="menu-overlay" />
                  <nav className="menu-block" id="append-menu-header">
                    <div className="mobile-menu-head">
                      <Link to="/">
                        <img src={logo.logo} alt="brand logo" />
                      </Link>
                      <div className="current-menu-title" />
                      <div className="mobile-menu-close">×</div>
                    </div>
                    <ul className="site-menu-main">

                      <li className="nav-item">
                        <Link to="/" className="nav-link-item drop-trigger">
                          Home
                        </Link>
                        <Link to="/pricing" className="nav-link-item drop-trigger">
                          Pricing
                        </Link>
                        <Link to="/about-us" className="nav-link-item drop-trigger">
                          About us
                        </Link>
                        <Link to="/contact" className="nav-link-item drop-trigger">
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div className="mobile-menu-trigger">
                  <div className="nav-item dropdown ml-3 me-3">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fas fa-bars menu-bar " style={{ fontSize: '28px' }}></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end p-3">
                      <li>
                        <Link className="dropdown-item" style={{ fontSize: '20px' }} to="/">

                          Home
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" style={{ fontSize: '20px' }} to="/pricing">
                          Pricing
                        </Link>
                      </li>


                      <li>
                        <Link className="dropdown-item" style={{ fontSize: '20px' }} to='/about-us'>About Us</Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" style={{ fontSize: '20px' }} to='/contact'>Contact Us</Link>
                      </li>


                    </ul>
                  </div>
                </div>

                {user ? (
                  <>
                    {profile.map(pro => pro.userEmail === user?.email && (
                      <div className="nav-item dropdown mt-3 me-3">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={pro.profileImg || "https://st3.depositphotos.com/15648834/17930/v/450/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"}
                            alt="Profile"
                            className="rounded-circle me-2"
                            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                          />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end p-3">
                          <li>
                            <Link className="dropdown-item" to="/user-dashboard">
                              <i class="fa-solid fa-bars text-primary"></i>{" "}
                              Dashboard
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/pending-payment/">
                              <i class="fas fa-money-check-alt text-primary"></i>{" "}
                              Pending Payment
                            </Link>
                          </li>


                          <li>
                            <Link className="dropdown-item" to='/find-leads'> <i class="fas fa-window-maximize text-primary"></i>{" "}Find Leads</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={`/update-profile/${pro._id}`}> <i class="fa-solid fa-user text-primary"></i>{" "} Edit Profile</Link>
                          </li>


                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li>
                            <button className="dropdown-item" onClick={handleSignout}>
                              <i class="fa-solid fa-right-from-bracket text-primary"></i>
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    ))}


                    {isAdmin && (
                      <Link
                        to="/admin/dashboard"
                        className="btn-masco btn-masco--header rounded-pill btn-fill--up"
                      >
                        <span>Admin</span>
                      </Link>
                    )}
                  </>
                ) : (
                  <Link
                    to="/login"
                    className="btn-masco btn-masco--header rounded-pill btn-fill--up"
                  >
                    <span>Login</span>
                  </Link>
                )}














              </nav>
          }
        </div>
      </header>

      {shouldRenderPageHero && <PageHero />}
    </>
  );
};

export default NavBar;
